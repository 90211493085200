// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import QrScanner from './QrScanner';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/qr_scan" element={<QrScanner />} />
        {/* Puedes añadir otras rutas aquí */}
      </Routes>
    </Router>
  );
}

export default App;
