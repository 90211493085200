// src/QrScanner.js
import React, { useRef, useState, useEffect } from 'react';
import Webcam from 'react-webcam';
import { BrowserMultiFormatReader, NotFoundException } from '@zxing/library';
import './QrScanner.css'; // Asegúrate de importar el archivo CSS

const QrScanner = () => {
  const [result, setResult] = useState('');
  const webcamRef = useRef(null);
  const codeReaderRef = useRef(null);

  useEffect(() => {
    codeReaderRef.current = new BrowserMultiFormatReader();

    const intervalId = setInterval(() => {
      if (webcamRef.current) {
        codeReaderRef.current.decodeFromVideoDevice(null, webcamRef.current.video, (result, err) => {
          if (result) {
            setResult(result.text);
          }
          if (err && !(err instanceof NotFoundException)) {
            console.error(err);
          }
        });
      }
    }, 1000); // Ajusta el intervalo según sea necesario

    return () => clearInterval(intervalId);
  }, []); // Dependencias vacías significa que este efecto se ejecuta solo una vez después de la renderización inicial

  const showAlertMessage = (message) => {
    switch (message) {
      case 'A':
        alert('Se registro Desafiando Estereotipos');
        break;
      case 'B':
        alert('Se registro Descubriendo la Autenticidad');
        break;
      case 'C':
        alert('Se registro Entre Sombras y Luces');
        break;
      case 'D':
        alert('Se registro Hackeando el Cambio');
        break;
      default:
        alert('Registro General con exito');
    }
  };

  const sendResult = async (endpoint) => {
    if (result === '') return; // No hacer nada si no hay resultado disponible

    try {
      const response = await fetch(`https://nazaret.app/${endpoint}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ code: result }),
      });

      const data = await response.json(); // Asume que la respuesta es JSON

      if (response.ok) {
        showAlertMessage(data.message); // Muestra el mensaje de la respuesta
        setResult(''); // Reinicia el resultado después de enviar
      } else {
        alert(`Failed to send value to /${endpoint}`);
      }
    } catch (error) {
      console.error('Error sending value:', error);
      alert('Error sending value');
    }
  };

  return (
    <div className="qr-scanner-container">
      <div className="webcam-container">
        <Webcam
          audio={false}
          ref={webcamRef}
          screenshotFormat="image/jpeg"
          width="100%"
          height="100%"
        />
      </div>
      <div className="result-container">
        <p className="result-text">{result}</p>
        <div className="button-container">
          <button className="btn-purple" onClick={() => sendResult('general')}>General</button>
          <button className="btn-orange" onClick={() => sendResult('taller1')}>Taller 1</button>
          <button className="btn-yellow" onClick={() => sendResult('taller2')}>Taller 2</button>
        </div>
      </div>
    </div>
  );
};

export default QrScanner;
